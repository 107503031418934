import React from 'react'
import './Plans.css'
import {plansData} from '../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'
const Plans = () => {
  return (
    <div className='plans-container'>
        <div className="blur plan-blur-1"></div>
        <div className="blur plan-blur-2"></div>
        <div className="programs-header">
            <span className="stroke-text">READY TO START</span>
            <span>YOUR JOURNEY</span>
            <span className="stroke-text">NOW WITHUS</span>
        </div>   

        <div className="plans">
            {plansData.map((plan,index) =>(
                <div className="plan" key={index}>
                    {plan.icon}
                    <span>{plan.name}</span>
                    <span>$ {plan.price}</span>
                    <div className="features">
                        {plan.features.map((item,index) =>(
                            <div className="feature" key={index}>
                                <img src={whiteTick} alt="" />
                                <span>{item}</span>
                            </div>
                        ))}
                    </div>
                    <div>
                        <span>See more benefits</span>
                    </div>
                    <button className='btn'>Join Now</button>
                </div>
            ))}
        </div> 
    </div>
  )
}

export default Plans
